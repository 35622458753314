<template>
<b-overlay :show="loading" :opacity="0.5">
  <section>
    <div class="d-none d-md-block mt-5">
      <referral-statistics :key="referralStatsRefreshKey" />
      <div class="mt-md-4">
        <list-table
          :records="commissions"
          :columns="tableColumns"
          :total-records="totalRecords"
          :show-search="false"
          :show-filter-toggle-button="false"
          :current-page-number.sync="currentPage"
        >
          <template #filters>
            <ReferralCommissionsListFilters 
              class="flex-grow" 
              :status-filter.sync="statusFilter" 
              :can-withdraw-commission="canWithdrawCommission"
              :max-amount="available_balance"
              @withdrawal-success="onWithdrawalSuccess"
            />
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ capitalizeFirstWord(data.item.status) }}
            </b-badge>
          </template>

        </list-table>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { debounce, get } from "lodash";
import { formatDate, formatMoney } from "@core/utils/utils";
import ListTable from "@core/components/ListTable/ListTable.vue";
import StatCard from "@/pages/client/referrals/shared/StatCard.vue";
import ReferralCommissionsListFilters from "@/pages/client/referrals/list/ReferralCommissionsListFilters.vue";
import {
  BOverlay,
  BDropdown,
  BBadge,
  BButton,
  BDropdownItem,
  BDropdownGroup,
} from "bootstrap-vue";

import ReferralStatistics from "@/pages/client/referrals/list/ReferralStatistics.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListReferralCommissions",
  components: {
    ReferralCommissionsListFilters,
    ListTable,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    StatCard,
    ReferralStatistics
  },
  data() {
    return {
      commissions: [],
      loading: false,
      statusFilter: "",
      tableColumns: [
        {
          key: 'created',
          sortable: false,
          formatter: val => `${formatDate(val)}`,
          label: 'Date',
        },
        {
          key: 'amount',
          sortable: false,
          label: 'Amount',
          formatter: val => `¢${formatMoney(val)}`
        },
        {
          key: 'status',
          sortable: false
        },
      ],
      available_balance: 0,
      debounceFetchAllData: null,
      referralStatsRefreshKey: 0
    }
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    commissionWithdrawalThreshold() {
      return this.getValueFromSource(this.settings, "loan_settings.referral_commission_withdrawal_threshold", 50)
    },
    canWithdrawCommission() {
      return this.available_balance > 0 && this.available_balance > this.commissionWithdrawalThreshold;
    }
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    statusFilter: watchHandlerRequiresPageReset,
    currentPage: watchHandler
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchReferralCommissions, 500);
    const { status, page = 1, limit = this.perPage } = this.$route.query;

    this.perPage = +limit;
    this.currentPage = +page;
    this.statusFilter = status;

    this.fetchReferralCommissionsAvailableBalance()
  },
  methods: {
    async fetchReferralCommissions() {
      try {
        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
        };

        this.$router.push({
          query
        }).catch(() => {});

        const request = await useJwt.clientService.fetchReferralCommissions(query);
        const {
          data,
          pagination,
        } = request.data;
        this.totalRecords = pagination.totalRecords
        this.commissions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async fetchReferralCommissionsAvailableBalance() {
      try {
        this.loading = true;

        const response = await useJwt.clientService.fetchReferralCommissionsAvailableBalance();
        const { available_balance } = response.data.data;
        this.available_balance = available_balance;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onWithdrawalSuccess() {
      this.$router.replace({ name: "referral-commissions-withdrawals" });
    }
  }
}
</script>

<style scoped>

</style>
